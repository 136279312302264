import React, { useRef, useState, useCallback } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    container,
    innerContainer,
    contentBox,
    content,
    bottles,
    text,
    swirl,
    text01,
    text02,
    bottleOld,
    bottleNew,
    backgroundText,
    absoluteBox,
    grainMono01,
    grainMono02,
    grainMono03,
    grainMono04,
    animate,
} from './szlachetna-inspiration.module.scss';
import { useIntersectionObserver } from "../../hooks/use-intersection-observer";

import SzlachetnaContent from "../molecules/szlachetna-content";
import Markdown from "../hoc/markdown";

const oldBottleSrc = "../../assets/images/soplica-szlachetna/szlachetna-inspiration-old-bottle.png";
const newBottleSrc = "../../assets/images/soplica-szlachetna/szlachetna-inspiration-new-bottle.png";
const swirlSrc = "../../assets/images/soplica-szlachetna/szlachetna-swirl.png";
const grainMono01Src = "../../assets/images/soplica-szlachetna/szlachetna-grain-mono-01.png";
const grainMono02Src = "../../assets/images/soplica-szlachetna/szlachetna-grain-mono-02.png";

interface ISzlachetnaInspirationProps {
    className?: string;
}

const SzlachetnaInspiration: React.FC<ISzlachetnaInspirationProps> = ({ className = '' }) => {
    const { t } =useI18next();
    const bottlesRef = useRef<HTMLDivElement>(null);
    const [areBottlesIntersecting, setAreBottlesIntersecting] = useState(false);

    const handleObserveBottles = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setAreBottlesIntersecting(entry.isIntersecting);
        });
    }, []);

    useIntersectionObserver({ element: bottlesRef.current, callback: handleObserveBottles, config: { threshold: 0.35 } });

    return (
        <div className={`${className} ${container}`}>
            <div className={innerContainer}>
                <div className={`${absoluteBox} ${grainMono01}`}>
                    <StaticImage src={grainMono01Src} alt="" />
                </div>
                <div className={`${absoluteBox} ${grainMono02}`}>
                    <StaticImage src={grainMono02Src} alt="" />
                </div>
                <div className={`${absoluteBox} ${grainMono03}`}>
                    <StaticImage src={grainMono01Src} alt="" />
                </div>
                <div className={`${absoluteBox} ${grainMono04}`}>
                    <StaticImage src={grainMono02Src} alt="" />
                </div>
                <SzlachetnaContent
                    className={contentBox}
                    title={
                        <>
                            <span>{t('szlachetna.inspiration.title.1')}</span><br/>
                            {t('szlachetna.inspiration.title.2')}
                        </>
                    }
                    content={
                        <Markdown className={content}>
                            {t('szlachetna.inspiration.content')}
                        </Markdown>
                    }
                />
                <div ref={bottlesRef} className={`${bottles} ${areBottlesIntersecting ? animate : ''}`}>
                    <p className={backgroundText}>
                        Szlachetna
                    </p>
                    <div className={`${text} ${text01}`}>
                        {t('szlachetna.inspiration.text.1')}
                        <div className={swirl}>
                            <StaticImage src={swirlSrc} alt="" />
                        </div>
                    </div>
                    <div className={bottleOld}>
                        <StaticImage src={oldBottleSrc} alt={t('szlachetna.inspiration.old.alt')} quality={90} />
                    </div>
                    <div className={bottleNew}>
                        <StaticImage src={newBottleSrc} alt={t('szlachetna.inspiration.old.alt')} quality={90} />
                    </div>
                    <div className={`${text} ${text02}`}>
                        {t('szlachetna.inspiration.text.2')}
                        <div className={swirl}>
                            <StaticImage src={swirlSrc} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SzlachetnaInspiration;