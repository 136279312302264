// extracted by mini-css-extract-plugin
export var absoluteBox = "szlachetna-grains-module--absolute-box--2d5cf";
export var animate = "szlachetna-grains-module--animate--f4bc6";
export var animationBox = "szlachetna-grains-module--animation-box--ad3a4";
export var card = "szlachetna-grains-module--card--dd7d8";
export var cardBackground = "szlachetna-grains-module--card-background--5491f";
export var cardContent = "szlachetna-grains-module--card-content--d11f4";
export var cardVideo = "szlachetna-grains-module--card-video--28219";
export var container = "szlachetna-grains-module--container--2ed01";
export var errorBlink = "szlachetna-grains-module--error-blink--4ca9c";
export var grain01 = "szlachetna-grains-module--grain-01--96524";
export var grain02 = "szlachetna-grains-module--grain-02--c447b";
export var grainMono01 = "szlachetna-grains-module--grain-mono-01--77175";
export var grainMono02 = "szlachetna-grains-module--grain-mono-02--51972";
export var grains = "szlachetna-grains-module--grains--c5ccd";
export var image = "szlachetna-grains-module--image--5821d";
export var innerContainer = "szlachetna-grains-module--inner-container--49113";
export var title = "szlachetna-grains-module--title--2343b";
export var video = "szlachetna-grains-module--video--e0ab4";