// extracted by mini-css-extract-plugin
export var absoluteBox = "szlachetna-bottle-module--absolute-box--f2fdc";
export var animate = "szlachetna-bottle-module--animate--dd911";
export var animationBox = "szlachetna-bottle-module--animation-box--a4845";
export var backgroundBox = "szlachetna-bottle-module--background-box--10a1f";
export var backgroundImage = "szlachetna-bottle-module--background-image--44204";
export var bottle = "szlachetna-bottle-module--bottle--bebbd";
export var container = "szlachetna-bottle-module--container--35b6d";
export var contentBox = "szlachetna-bottle-module--content-box--5b7a8";
export var errorBlink = "szlachetna-bottle-module--error-blink--9f13b";
export var grain01 = "szlachetna-bottle-module--grain-01--4b004";
export var grain02 = "szlachetna-bottle-module--grain-02--7eb63";
export var grainMono01 = "szlachetna-bottle-module--grain-mono-01--fafcd";
export var grainMono02 = "szlachetna-bottle-module--grain-mono-02--59270";
export var grainMono03 = "szlachetna-bottle-module--grain-mono-03--b7fa8";
export var imageBox = "szlachetna-bottle-module--image-box--67b04";
export var title = "szlachetna-bottle-module--title--b2585";
export var weed01 = "szlachetna-bottle-module--weed-01--5b77a";
export var weed02 = "szlachetna-bottle-module--weed-02--5bc51";